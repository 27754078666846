.mainContainer {
  width: 100%;
  min-height: calc(100vh - 60px);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.header {
  margin: 20px 0px 20px 0px;
}

.previewImagesWrapper {
  width: 100%;
  height: 60%;
  float: left;
  display: block;
  position: absolute;
  top: 80px;
  left: 0;
  .previewImageWrapper {
    width: 28%;
    position: relative;
    float: left;
    height: 100%;
    margin: 0px 2% 0px 2%;
    p {
      text-align: center;
      font-size: 20px;
      margin: 5px 0px 5px 0px;
    }
    img {
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 100%;
    }
  }
}

.downloadLink {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  color: #C9F0F5;
  background-color: transparent;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
}

@media only screen and (max-width: 950px){
  .previewImagesWrapper {
    width: 100%;
    height: 350px;
    position: absolute;
    top: 80px;
    left: 0;
    .previewImageWrapper {
      p {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 770px){
  .previewImagesWrapper {
    .previewImageWrapper {
      p {
        font-size: 14px;
      }
    }
  }

  .downloadLink {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    color: #C9F0F5;
    background-color: transparent;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
  }
}

@media only screen and (max-width: 560px){
  .previewImagesWrapper {
    .previewImageWrapper {
      p {
        font-size: 12px;
      }
    }
  }

  .downloadLink {
    font-size: 12px;
  }
}